import mixpanelImpl from 'mixpanel-browser';
import config, { MIX_PANEL_APP_TYPE } from '../constants/Config';

const token = config.get('MIX_PANEL_API_TOKEN');

const mixpanelAnalyticsFactory = mixpanel => ({
    init: () => mixpanel.init(token),

    track: (eventName, extraData) => mixpanel.track(eventName, extraData),

    setUser: (identity) => {
        mixpanel.identify(identity.userName);

        const info = {};

        info.$name = identity.userName;
        info.$userName = identity.userName;
        info.$appType = MIX_PANEL_APP_TYPE;

        if (identity.firstName) {
            info.$first_name = identity.firstName;
        }

        if (identity.lastName) {
            info.$last_name = identity.lastName;
        }

        if (identity.accountId) {
            info.$accountId = identity.accountId;
        }

        if (identity.email) {
            info.$email = identity.email;
        }

        if (identity.accountName) {
            info.$accountName = identity.accountName;
        }

        if (identity.userTz) {
            info.$userTz = identity.userTz;
        }

        if (identity.userId) {
            info.$userId = identity.userId;
        }

        mixpanel.people.set(info);
    },
});

export default mixpanelAnalyticsFactory(mixpanelImpl);
