/* @flow */

import React, { Component, Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { reduxConnect } from '../../../hoc/index';
import * as actions from './actions.recordList';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import MaintenanceFilterForm from '../../../components/Maintenance/MaintenanceFilterForm';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';
import AddServiceRecord from './AddServiceRecord';
import {
    convertDateInGivenFormat,
    getDistanceString,
    getDistanceToDisplay,
    getEngineHoursToDisplay,
    changeDateFormat,
    downloadFile,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getContentType,
} from '../../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import { getServiceRecordsUrl } from './epics.recordList';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import AppLoader from '../../../components/AppLoader';
import { RECORD_LIST_PREF_KEY, RECORD_LIST_ASSET_EDIT_PREF_KEY } from './constants.recordList';
import { getSplittedHeader } from '../../../helper-classes/utility-react-functions';
import analytics from '../../../analytics';

export type Props = {
    classes: any,
    isUpdating: boolean,
    fetchRecordList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
    isEditable: boolean,
    assetId: string,
    customListHeaders: Array<{show: boolean}>,
    noDataMessage: Function,
    tableTitle: string,
    overrideStyle: Object,
    page: string,
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
    },
    page: number,
    order: string,
    orderBy: string,
    rowsPerPage: number,
    selectedFilters: Array<{
        id: string,
        property: string,
        data: {
            id: string
        },
    }>,
    isAddEditShown: boolean,
    selectedRecord: {
        assetId: string,
        assetName: string,
        planId: string,
        planName: string,
        engineHours: number,
        odometer: number,
        cost: number,
        notes: string,
        resetStatus: boolean,
        recordId: string,
        serviceDate: string,
    },
    editMode: boolean,
    showListing: boolean,
    selectedOption: Object,
    openExport: boolean,
    loading: boolean,
};

const styles = () => ({
    hide: {
        display: 'none !important',
    },
    show: {
        display: 'block !important',
    },
});

class RecordList extends Component<Props, State> {
    userPreferences: any;
    preferenceKey: string;
    static defaultProps = {
        isEditable: true,
        tableTitle: 'Service Records',
    };
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                ischeckBox: false,
                isEditable: props.isEditable,
                editTitle: 'Edit Service Record',
                listHeaders: props.customListHeaders ||
                    this.getListHeader(),
                listData: [],
                totalRecords: 0,
            },
            isAddEditShown: false,
            selectedRecord: {
                recordId: '',
                assetId: '',
                assetName: '',
                planId: '',
                planName: '',
                engineHours: 0,
                odometer: 0,
                cost: 0,
                notes: '',
                serviceDate: '',
                resetStatus: true,
            },
            editMode: true,
            page: 0,
            rowsPerPage: RECORDS_PER_PAGE,
            selectedFilters: MaintenanceFilterHelper.recordListPreselects,
            order: 'desc',
            orderBy: 'id',
            showListing: false,
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
            openExport: false,
            loading: false,
        };
        this.userPreferences = null;
        if (props.page && props.page === 'asset-edit') {
            this.preferenceKey = RECORD_LIST_ASSET_EDIT_PREF_KEY;
        } else {
            this.preferenceKey = RECORD_LIST_PREF_KEY;
        }
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, this.preferenceKey);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.assetId !== this.props.assetId) {
            this.setState({ page: 0 });
        }
        if (nextprops.records && nextprops.records.length) {
            const { records, totalRecords } = nextprops;
            const formattedRecordList = records.map((item => ({
                ...item,
                assetName: item.assetRef.name,
                serviceDate: item.serviceDate ? convertDateInGivenFormat(item.serviceDate, 'MMM D, YYYY') : '',
                odometer: getDistanceToDisplay(item.odometer),
                engineHours: getEngineHoursToDisplay(item.engineHours),
                cost: item.cost ? item.cost.toFixed(2) : '0',
            })));
            const { tableData } = this.state;
            tableData.listData = formattedRecordList;
            tableData.totalRecords = totalRecords;
            this.setState({
                tableData,
            });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({
                tableData,
            });
        }
    }

    componentDidUpdate(prevprops: any) {
        if (prevprops.assetId !== this.props.assetId) {
            const page = 0;
            const {
                rowsPerPage,
                order,
                orderBy,
                selectedFilters,
            } = this.state;
            this.getRecords(page, rowsPerPage, order, orderBy, selectedFilters);
        }
    }

    getFilterValue = (selectedFilters) => {
        let decoratedFilter = selectedFilters.slice();

        if (this.props.assetId) {
            decoratedFilter = this.applyPreselectedAssetId(this.props.assetId, decoratedFilter);
        }

        return decoratedFilter;
    }

    getRecords(page, rowsPerPage, order, orderBy, selectedFilters) {
        this.props.fetchRecordList(
            page,
            rowsPerPage,
            order,
            orderBy,
            this.getFilterValue(selectedFilters),
        );
    }

    applyPreselectedAssetId = (assetId, filters) => {
        filters.push({
            id: assetId, property: 'assetId', value: assetId, data: { id: assetId },
        });

        return filters;
    }

    addClick = () => this.showModal(false);

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { assetName: 'Asset', ...getColumnPreferences('assetName', preferences), disableSort: true },
            { servicePlanName: 'Service Plan Name', ...getColumnPreferences('servicePlanName', preferences), disableSort: true },
            { serviceDate: 'Service Date', ...getColumnPreferences('serviceDate', preferences) },
            {
                ...getSplittedHeader(`Odometer (${getDistanceString()})`, '', 'odometer'),
                // displayUnit: getDistanceString(),
                ...getColumnPreferences('odometer', preferences),
                disableSort: true,
            },
            {
                engineHours: 'Engine Hours',
                displayUnit: 'hrs',
                ...getColumnPreferences('engineHours', preferences),
                disableSort: true,
            },
            { cost: 'Cost', ...getColumnPreferences('cost', preferences) },
            { notes: 'Notes', ...getColumnPreferences('notes', preferences), disableSort: true },
        ];

        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }

        return columns;
    }

    getData = () => {
        this.getRecords(
            this.state.page,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.selectedFilters,
        );
        this.setState({ showListing: true });
    }

    listTypeContextAction = (action) => {
        if (action === 'filter') {
            return this.filterComponent();
        } else if (action === 'add') {
            this.addClick();
        } else if (action === 'export') {
            this.openExport(true);
        }
        return true;
    };

    showModal(editMode:boolean) {
        this.setState({ isAddEditShown: true, editMode });
    }

    clickRow = (n: any) => {
        this.setState({
            selectedRecord: {
                assetId: n.assetId,
                assetName: n.assetName,
                planId: n.servicePlanId,
                planName: n.servicePlanName,
                engineHours: n.engineHours,
                odometer: n.odometer,
                serviceDate: changeDateFormat((new Date(n.serviceDate)).toISOString(), 'YYYY-MM-DD'),
                notes: n.notes,
                cost: n.cost,
                resetStatus: n.resetStatus,
                recordId: n.id,
            },
        });
        this.showModal(true);
    };

    closeAddEdit = () => {
        this.setState({
            selectedRecord: {
                recordId: '',
                assetId: '',
                assetName: '',
                planId: '',
                planName: '',
                engineHours: 0,
                odometer: 0,
                cost: 0,
                notes: '',
                serviceDate: '',
                resetStatus: true,
            },
            isAddEditShown: false,
        });
        this.getRecords(
            this.state.page,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.selectedFilters,
        );
    };

    sortTableColumn = (order, orderBy, page) => {
        this.setState({ order, orderBy, rowsPerPage: page.rowsPerPage }, () => {
            saveUserPreference(this, this.preferenceKey);
        });
        this.getRecords(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.state.selectedFilters,
        );
        const params = {
            feature: 'Maintenance',
            sortColumn: orderBy,
        };
        analytics.track('SERVICE_RECORDS_SORT', params);
    };

    onFiltersSelected = (selectedFilters) => {
        this.getRecords(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            selectedFilters,
        );
        this.setState({ selectedFilters });
        let assets = true;
        let date = '';
        let plans = true;
        selectedFilters.forEach((x) => {
            if (x.property === 'assetId') {
                assets = false;
            } if (x.property === 'filterDate') {
                date = x.label;
            } if (x.property === 'servicePlanId') {
                plans = false;
            }
        });
        const params = {
            feature: 'Maintenance',
            allAssets: assets,
            dateRange: date,
            allPlans: plans,
        };
        analytics.track('SERVICE_RECORDS_FILTER', params);
    };

    filterComponent = () => {
        const { selectedFilters } = this.state;
        const { dateRangeFilter } = MaintenanceFilterHelper;
        const filterOptions = [];
        const dateRangeSelected = MaintenanceFilterHelper.dateRangeSelected(selectedFilters);
        dateRangeFilter.subgroupsHidden = !dateRangeSelected;
        dateRangeFilter.filterTitle = 'Date Range *';
        filterOptions.push(dateRangeFilter);
        filterOptions.push(MaintenanceFilterHelper.assetFilterWithGlobalId);
        filterOptions.push(MaintenanceFilterHelper.servicePlanFilter);

        return (
            <MaintenanceFilterForm
                filters={filterOptions}
                selectedFilters={this.state.selectedFilters}
                defaultSelections={MaintenanceFilterHelper.recordListPreselects}
                onFilterSelected={this.onFiltersSelected}
            />
        );
    };

    showLoader = (loading: boolean) => this.setState({ loading });

    getAddEditModal = () => (
        this.state.isAddEditShown ?
            (<AddServiceRecord
                windowTitle={this.state.editMode ? 'Edit Service Record' : 'Create New Service Record'}
                isShown={this.state.isAddEditShown}
                closeAddEdit={this.closeAddEdit}
                recordId={this.state.selectedRecord.recordId}
                assetId={this.state.selectedRecord.assetId}
                servicePlanId={this.state.selectedRecord.planId}
                assetName={this.state.selectedRecord.assetName}
                servicePlanName={this.state.selectedRecord.planName}
                servicePlanDescription={this.state.selectedRecord.planName}
                engineHours={this.state.selectedRecord.engineHours}
                odometer={this.state.selectedRecord.odometer}
                serviceDate={this.state.selectedRecord.serviceDate}
                cost={this.state.selectedRecord.cost}
                notes={this.state.selectedRecord.notes}
                resetStatus={this.state.selectedRecord.resetStatus}
                editMode={this.state.editMode}
                prepopulated={false}
                showLoader={this.showLoader}
            />)
            :
            null);

    rearrangeColumns = listHeaders => (rearrangeColumns(
        this,
        listHeaders,
        { prefKey: this.preferenceKey },
    ));

    showHideColumn = header => (showHideColumns(
        this,
        header,
        { prefKey: this.preferenceKey },
    ));

    dialogHandleClick = (type, value) => {
        if (type === 'show') this.setState({ selectedOption: value });
        else if (type === 'download') {
            const { selectedOption } = this.state;
            this.openExport(false);
            this.setState({ loading: true });
            let url = getServiceRecordsUrl(
                {
                    pageNumber: this.state.page,
                    pageSize: this.state.rowsPerPage,
                    order: this.state.order,
                    orderBy: this.state.orderBy,
                    filtersToSend: this.getFilterValue(this.state.selectedFilters),
                },
                false,
            );
            url = `${url}&timezone=${getUserSettingTimeZone()}&unitOfMeasurement=${getDistanceStringLong()}`;
            downloadFile(
                url,
                `Service Records ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                {
                    type: selectedOption.value,
                    headers: { Accept: getContentType(selectedOption.value) },
                },
                () => this.setState({ loading: false }),
            );
        } else this.openExport(false);
    }

    openExport = openExport => this.setState({ openExport });

    render() {
        const { selectedOption } = this.state;
        const { classes } = this.props;

        return (
            <Fragment>
                {this.state.loading && <AppLoader type="fullScreen" />}
                {this.getAddEditModal()}
                {this.state.showListing ?
                    <div className={this.state.isAddEditShown ? classes.hide : classes.show}>
                        {!this.props.isUpdating
                            && this.state.tableData.totalRecords === 0
                            && this.props.noDataMessage ?
                            this.props.noDataMessage :
                            <ListComponent
                                redirectTo={this.props.history.push}
                                tableData={this.state.tableData}
                                loadNextPage={(page, rowsPerPage, order, orderBy) => {
                                    const updateUserPref = isUpdatePreference(
                                        this,
                                        { rowsPerPage, order, orderBy },
                                    );
                                    this.setState({
                                        page, order, orderBy, rowsPerPage,
                                    }, () => {
                                        if (updateUserPref) {
                                            saveUserPreference(this, this.preferenceKey);
                                        }
                                    });
                                    this.getRecords(
                                        page,
                                        rowsPerPage,
                                        order,
                                        orderBy,
                                        this.state.selectedFilters,
                                    );
                                }}
                                rearrangeColumns={this.rearrangeColumns}
                                resetTable={this.props.resetTable}
                                isUpdating={this.props.isUpdating}
                                tableTitle={this.props.tableTitle}
                                tableButtons={this.props.isEditable ? ['Filter List', 'Add Items', 'Export'] : ['Export']}
                                dialogboxInfo={{
                                    title: `Export data as ${selectedOption.lable} for Service Records`,
                                    dialogHandleClick: this.dialogHandleClick,
                                    showDialog: this.state.openExport,
                                    selectedOption,
                                    options: [{
                                        lable: 'CSV',
                                        value: 'csv',
                                        key: 'CSV',
                                    }],
                                }}
                                filtersApplied={this.state.selectedFilters.length > 1}
                                sortTableColumn={this.sortTableColumn}
                                showHideColumn={this.showHideColumn}
                                clickRow={this.clickRow}
                                listTypeContextAction={this.listTypeContextAction}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                showSearch={false}
                                resizableHeader
                                overrideStyle={this.props.overrideStyle}
                                rowsPerPage={this.state.rowsPerPage}
                            />
                        }
                    </div>
                    :
                    <div style={{ height: '100%' }}>
                        <AppLoader type="fullScreen" />
                    </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    records: state.recordList.records,
    totalRecords: state.recordList.totalRecords,
    isUpdating: state.recordList.isUpdating,
});
export default withStyles(styles)(reduxConnect(RecordList, actions, mapStateToProps));

