import consoleAnalytics from './consoleAnalytics';
import mixpanelAnalytics from './mixpanelAnalytics';
import config, { MIX_PANEL_APP_TYPE, LOCAL_MIX_PANEL_APP_TYPE } from '../constants/Config';
import { getLocalStorageValue, getAppUserSettings, getUsername } from './../helper-classes/utility-functions';

const isIframe = () => window.location.pathname.match(/^\/iframe/i);

const getUserData = (object) => {
    const defaultProps = { appType: isIframe() ? LOCAL_MIX_PANEL_APP_TYPE : MIX_PANEL_APP_TYPE };

    if (!object) return defaultProps;
    const newObj = { ...object, ...defaultProps };
    const userSettings = isIframe() ? { accountName: getLocalStorageValue('currentAccountName'), accountId: getLocalStorageValue('currentAccountGlobalId') } : getAppUserSettings();
    newObj.accountId = userSettings && userSettings.accountId ? userSettings.accountId : '';
    newObj.accountName = userSettings && userSettings.accountName ? userSettings.accountName : '';
    newObj.userId = getLocalStorageValue('userid') || '';
    newObj.userName = getUsername() || '';

    if (!newObj.userName && newObj.username) {
        newObj.userName = newObj.username;
        delete newObj.username;
    }
    newObj.userName = newObj.userName.toLowerCase();
    newObj.userTz = userSettings && userSettings.userTz ? userSettings.userTz : '';
    newObj.email = getLocalStorageValue('email') || '';
    return newObj;
};

export const analyticsFactory = providers => ({

    init: () => providers.forEach(x => x.init && x.init()),

    // send in a copy of the extraData in case a provider mutates it
    track: (eventName, extraData) => {
        const data = { ...getUserData(extraData) };
        const impersonated = getLocalStorageValue('fleet-impersonated') || '';
        const isLoginOrLogout = eventName === 'LOGOUT_SUCCESS' || eventName === 'LOGIN_SUCCESS';
        if (data && impersonated === 'true' && !isLoginOrLogout) {
            data.impersonated = 'YES';
        }
        return providers.forEach(x => x.track && x.track(eventName, data));
    },
    // send in a copy of the identity in case a provider mutates it
    setUser: (identity) => {
        const newIdentity = identity;
        if (newIdentity && newIdentity.username && typeof newIdentity.username === 'string') {
            newIdentity.username = newIdentity.username.toLowerCase();
        }
        return providers.forEach(x => x.setUser && x.setUser({ ...getUserData(newIdentity) }));
    },

    // clear the user info
    clearUser: () => providers.forEach(x => x.clearUser && x.clearUser()),

});

const providersMap = {
    consoleAnalytics,
    mixpanelAnalytics,
};

const analyticsProviders = [];
const providers = config.get('PROVIDERS');
if (providers) {
    providers.split(',').forEach((s) => {
        analyticsProviders.push(providersMap[s.trim()]);
    });
}

export default analyticsFactory(analyticsProviders);
