/* @flow */
import React from 'react';
import {
    Grid, Typography, Radio, TextField, Table, FormControl, TableBody,
    TableCell, Tooltip, TableRow, Button, Divider, IconButton, Paper, Toolbar,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ValidatorForm } from 'react-material-ui-form-validator';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import AssetSearch from '../../../components/SharedComponents/AssetSearch/AssetSearch';
import { getDistanceString, getCorrectDistance, changeDateFormat, getPermissionValue, getDateErrorMessage } from '../../../helper-classes/utility-functions';
import { addServiceRecord, updateServiceRecord, deleteServiceRecord, getGrailsServicePlan, getServicePlans, getAssetDetails } from '../helper-classes/common-services';
import customStyles from './addServiceRecord.module.scss';
import Dialog from '../../../components/SharedComponents/Dialog/Dialog';
import back from '../../../assets/icons/back.png';
import { reduxConnect } from '../../../hoc';
import * as actions from '../MaintenanceStatus/actions.maintenanceStatus';
import AsyncSelectWrapper from '../../../components/SharedComponents/AsyncSelectWrapper';
import addRecordStyles from './AddServiceRecordStyles';
import analytics from '../../../analytics/index';
import { SAVE_BUTTON_CLICK_TIMEOUT } from './constants.recordList';

export type Props = {
    classes: any,
    windowTitle: string,
    isShown: boolean,
    closeAddEdit: Function,
    assetId: string,
    assetName: string,
    servicePlanId: string,
    servicePlanName: string,
    servicePlanDescription: string,
    recordId: string,
    resetStatus: boolean,
    serviceDate: string,
    odometer: number,
    maxOdometer: number,
    engineHours: number,
    maxEngineHours: number,
    cost: number,
    notes: string,
    editMode: boolean,
    prepopulated: boolean,
    response: Object,
    showLoader: Function,
};

export type State = {
    show: boolean,
    serviceDate: any,
    odometer: number,
    maxOdometer: number,
    engineHours: number,
    maxEngineHours:number,
    cost: number,
    notes: string,
    assetId: string,
    assetName: string,
    servicePlanId: string,
    servicePlanName: string,
    servicePlanDescription: string,
    resetStatus: boolean,
    recordId: string,
    serviceDate: string,
    selectedServicePlan: Object,
    distanceInterval: number,
    firstServiceOdometer: number,
    timePeriodInterval: number,
    firstServiceDate: any,
    engineHoursInterval: number,
    firstServiceEngineHrs: number,
    openDialog: boolean,
    dialogType: string,
    dialogTitle: string,
    errorMessage: ?string,
    isDelete: boolean,
}
type Ref = {current: any | HTMLDivElement};

class AddServiceRecord extends React.Component<Props, State> {
    dpRef: Ref;
    button: Array<{}>;
    saveButtonClicked: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            show: props.isShown,
            serviceDate: props.serviceDate ? props.serviceDate : changeDateFormat((new Date()).toISOString(), 'YYYY-MM-DD'),
            odometer: props.odometer || 0,
            maxOdometer: props.maxOdometer ? props.maxOdometer : 0,
            engineHours: props.engineHours ? props.engineHours : 0,
            maxEngineHours: props.maxEngineHours ? props.maxEngineHours : 0,
            cost: props.cost ? props.cost : 0,
            assetId: props.assetId ? props.assetId : '',
            assetName: props.assetName ? props.assetName : '',
            servicePlanId: props.servicePlanId ? props.servicePlanId : '',
            servicePlanDescription: props.servicePlanDescription ? props.servicePlanDescription : '',
            servicePlanName: props.servicePlanName ? props.servicePlanName : '',
            resetStatus: props.resetStatus !== null ? props.resetStatus : true,
            recordId: props.recordId ? props.recordId : '',
            notes: props.notes ? props.notes : '',
            distanceInterval: 0,
            firstServiceOdometer: 0,
            timePeriodInterval: 0,
            firstServiceDate: null,
            engineHoursInterval: 0,
            firstServiceEngineHrs: 0,
            selectedServicePlan: {},
            openDialog: false,
            dialogType: '',
            dialogTitle: '',
            errorMessage: null,
            isDelete: false,
            dateErrorMessage: '',
        };
        // create datepicker ref so you can open the calendar via button
        this.dpRef = React.createRef();
        this.button = [];
        this.saveButtonClicked = false;
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('keydown', (evt: any) => {
            const e = evt || window.event;
            if (this.state.show && e.keyCode === 27) {
                this.handleCancelClick();
            }
        });
    }

    componentDidMount() {
        if ((this.props.editMode || this.props.prepopulated) && this.state.servicePlanId) {
            getGrailsServicePlan(this.state.servicePlanId).then((response) => {
                if (response && response.data) {
                    const plan = response.data[0];
                    if (plan) {
                        this.setServicePlanDetailsInState(plan);
                    }
                }
            });

            if (this.state.assetId && this.state.assetId.length > 0) {
                getAssetDetails(this.state.assetId).then((response) => {
                    if (response && response.status === 200) {
                        this.setState({
                            maxOdometer:
                        response.odometer ? getCorrectDistance(Math.abs(response.odometer), 'mi') : this.state.odometer,
                            maxEngineHours:
                        response.engineHours ? response.engineHours : this.state.engineHours,
                        });
                    }
                });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.response && nextprops.response !== this.props.response) {
            if (nextprops.response.response
                && nextprops.response.response.status
                && nextprops.response.response.status === 201) {
                this.handleCancelClick();
            } else {
                this.setState({
                    dialogType: 'error',
                    openDialog: true,
                    errorMessage: 'Error',
                    dialogTitle: 'Failed',
                });
            }
        }

        if (nextprops.assetId && nextprops.assetId !== this.props.assetId) {
            this.setState({ assetId: nextprops.assetId });
        }

        this.setState({ show: nextprops.isShown });
        if (nextprops.editMode) {
            this.setState({
                recordId: nextprops.recordId,
                engineHours: nextprops.engineHours,
                maxEngineHours: nextprops.engineHours,
                odometer: getCorrectDistance(Math.abs(nextprops.odometer), 'mi'),
                maxOdometer: getCorrectDistance(Math.abs(nextprops.odometer), 'mi'),
                notes: nextprops.notes,
                cost: nextprops.cost,
                resetStatus: nextprops.resetStatus,
                serviceDate: nextprops.serviceDate,
            });
        }

        if (nextprops.prepopulated) {
            this.setState({
                engineHours: nextprops.engineHours,
                maxEngineHours: nextprops.engineHours,
                odometer: getCorrectDistance(Math.abs(nextprops.odometer), 'mi'),
                maxOdometer: getCorrectDistance(Math.abs(nextprops.odometer), 'mi'),
            });
        }
    }

    clearForm() {
        this.setState({
            recordId: '',
            assetId: '',
            assetName: '',
            odometer: 0,
            engineHours: 0,
            cost: 0,
            servicePlanId: '',
            servicePlanName: '',
            servicePlanDescription: '',
            notes: '',
            serviceDate: changeDateFormat(new Date().toISOString(), 'YYYY-MM-DD'),
            distanceInterval: 0,
            firstServiceOdometer: 0,
            timePeriodInterval: 0,
            firstServiceDate: 0,
            engineHoursInterval: 0,
            firstServiceEngineHrs: 0,
        });
    }

    handleCancelClick = () => {
        this.clearForm();
        this.props.closeAddEdit();
    }

    setServicePlanDetailsInState = (servicePlan) => {
        this.setState({
            servicePlanId: servicePlan.id,
            servicePlanName: servicePlan.name,
            servicePlanDescription: servicePlan.description,
            distanceInterval: getCorrectDistance(Math.abs(servicePlan.distanceInterval), 'mi'),
            firstServiceOdometer: getCorrectDistance(Math.abs(servicePlan.firstServiceOdometer), 'mi'),
            timePeriodInterval: servicePlan.timePeriodInterval,
            firstServiceDate: servicePlan.firstServiceDate ? changeDateFormat(servicePlan.firstServiceDate, 'MM/DD/YYYY') : servicePlan.firstServiceDate,
            engineHoursInterval: servicePlan.engineHoursInterval,
            firstServiceEngineHrs: servicePlan.firstServiceEngineHours,
        });
    };

    resetServiceDate = () => {
        if (moment(this.state.serviceDate).isValid()) {
            this.setState({
                serviceDate: moment(this.state.serviceDate),
                dateErrorMessage: '',
            });
        }
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
        this.resetServiceDate();
    }

    handleChangeInPlan = (selectedPlan) => {
        this.setServicePlanDetailsInState(selectedPlan.data);
        this.setState({ selectedServicePlan: selectedPlan });
    };

    handleAssetCleared = () => {
        this.setState({
            assetId: '',
            odometer: 0,
            maxOdometer: 0,
            engineHours: 0,
            maxEngineHours: 0,
            selectedServicePlan: {},
            servicePlanId: '',
            servicePlanName: '',
            servicePlanDescription: '',
            distanceInterval: 0,
            firstServiceOdometer: 0,
            timePeriodInterval: 0,
            firstServiceDate: null,
            engineHoursInterval: 0,
            firstServiceEngineHrs: 0,
        });
    }

    handleRowClick = selectedRow => this.setState({
        assetId: selectedRow.id,
        odometer: selectedRow.odometer,
        maxOdometer: selectedRow.odometer,
        engineHours: selectedRow.engineHours,
        maxEngineHours: selectedRow.engineHours,
    });

    handleDateChange = date => {
        if (!date) {
            this.setState({ dateErrorMessage: '' });
        } else if (!moment(date).isValid()) {
            this.setState({ dateErrorMessage: 'invalidDate' });
        } else if (moment(date).isAfter(moment(), 'd')) {
            this.setState({ dateErrorMessage: 'disableFuture' });
        } else {
            this.setState({ dateErrorMessage: '' });
            this.setState({ serviceDate: date });
        }
    }

    getDialogContent = () => {
        const { dialogType } = this.state;
        let content = '';
        if (dialogType === 'confirmDelete') {
            content = 'Are you sure you want to delete the service record?';
        } else if (dialogType === 'confirm') {
            content = 'Are you sure you want to reset the Maintenance Status?';
        } else if (dialogType === 'error') {
            content = this.state.errorMessage;
        } else if (dialogType === 'successDelete') {
            content = 'Service Record deleted successfully';
        }
        return content;
    };

    handleSaveButtonClick = () => {
        this.saveButtonClicked = true;
        setTimeout(() => {
            this.saveButtonClicked = false;
        }, SAVE_BUTTON_CLICK_TIMEOUT);
    }

    getDialogType = () => {
        const { dialogType } = this.state;
        let type = 'confirm';
        this.button = [{
            title: 'Yes',
            color: 'primary',
            variant: 'contained',
            handleClick: () => {
                if (!this.saveButtonClicked) {
                    this.handleSaveButtonClick();
                    this.setState({ openDialog: false }, () => {
                        this.saveRecord();
                    });
                }
            },
        }];

        if (dialogType === 'confirmDelete') {
            type = 'confirm';
            this.button.splice(0, 1, {
                title: 'Ok',
                color: 'primary',
                variant: 'contained',
                handleClick: () => {
                    this.setState({ openDialog: false });
                    if (this.state.isDelete) {
                        this.deleteRecord();
                        this.setState({ isDelete: false });
                    }
                },
            });
            this.button.splice(0, 0, {
                title: 'Cancel',
                color: 'primary',
                handleClick: () => this.setState({ openDialog: false, isDelete: false }),
            });
        } else if (dialogType === 'confirm') {
            type = 'confirm';
            this.button.splice(0, 0, {
                title: 'No', color: 'primary', variant: 'contained', handleClick: () => this.setState({ openDialog: false }),
            });
        } else if (dialogType === 'error') {
            type = 'error';
            this.button.splice(0, 1, {
                title: 'Ok', color: 'primary', variant: 'contained', handleClick: () => this.setState({ openDialog: false }),
            });
        } else if (dialogType === 'successDelete') {
            type = 'success';
            this.button.splice(0, 1, {
                title: 'Ok',
                color: 'primary',
                variant: 'contained',
                handleClick: () => {
                    this.setState({ openDialog: false });
                    this.handleCancelClick();
                },
            });
        }
        return type;
    };

    saveRecord() {
        const content = {
            accountId: localStorage.getItem('currentAccountUserId') || '',
            assetId: this.state.assetId,
            cost: this.state.cost,
            engineHours: this.state.engineHours,
            notes: this.state.notes,
            odometer: this.state.odometer,
            resetStatus: this.state.resetStatus,
            serviceDate: this.state.serviceDate,
            servicePlanId: this.state.servicePlanId,
            id: this.state.recordId,
        };

        this.props.showLoader(true);

        if (this.props.editMode) {
            updateServiceRecord(content).then((response) => {
                this.props.showLoader(false);
                if (response.status === 200) {
                    document.dispatchEvent(new Event('updateOverdueCount'));
                    this.handleCancelClick();
                    const params = {
                        feature: 'Maintenance',
                        assetName: this.props.assetName,
                        servicePlanName: this.props.servicePlanName,
                        resetServiceInterval: this.state.resetStatus,
                    };
                    analytics.track('EDIT_SERVICE_RECORD', params);
                } else if (response.status === 400) {
                    this.setState({
                        dialogType: 'error',
                        openDialog: true,
                        errorMessage: response.error || response.message,
                        dialogTitle: 'Failed',
                    });
                }
            });
        } else if (content.servicePlanId) {
            addServiceRecord(content).then((response) => {
                this.props.showLoader(false);
                if (response.status === 200) {
                    document.dispatchEvent(new Event('updateOverdueCount'));
                    this.handleCancelClick();
                    const params = {
                        feature: 'Maintenance',
                        servicePlanName: this.state.selectedServicePlan &&
                            this.state.selectedServicePlan.label,
                        resetServiceInterval: this.state.resetStatus,
                        addedFrom: this.props.recordId === '' ? 'Service Record' : 'Status',
                    };
                    analytics.track('ADD_SERVICE_RECORD', params);
                } else if (response.status === 400) {
                    this.setState({
                        dialogType: 'error',
                        openDialog: true,
                        errorMessage: response.error || response.message,
                        dialogTitle: 'Failed',
                    });
                }
            });
        } else {
            this.props.showLoader(false);
            this.setState({
                dialogType: 'error',
                openDialog: true,
                errorMessage: 'Insufficient Data',
                dialogTitle: 'Failed',
            });
        }
    }

    saveHandler = () => {
        if (this.state.resetStatus) {
            this.setState({ dialogType: 'confirm', openDialog: true, dialogTitle: 'Reset Maintenance Status' });
        } else if (!this.saveButtonClicked) {
            this.handleSaveButtonClick();
            this.saveRecord();
        }
    };

    setResetStatus = (e) => {
        this.setState({ resetStatus: (e.value === 'true') });
        this.resetServiceDate();
    };

    getFootNoteText = () => {
        const serviceDt = changeDateFormat(this.state.serviceDate, 'MM/DD/YYYY');
        return `This vehicle's service record was last reset on: ${serviceDt}`;
    };

    loadNext = loadedOptions => new Promise((resolve, reject) => {
        getServicePlans(loadedOptions.length, 30, this.state.assetId).then((response) => {
            if (response && response.total) {
                const { count, total, content } = response;
                const hasMore = loadedOptions.length + count < total;

                if (content) {
                    const optionData = content.map(datum => ({
                        value: datum.id,
                        label: datum.name,
                        data: datum,
                    }));
                    resolve({ options: optionData, hasMore });
                } else reject();
            } else reject();
        });
    });

    renderServicePlanDropDown = () => {
        const { selectedServicePlan } = this.state;
        const dropDown = (
            <FormControl className={customStyles.formField}>
                <AsyncSelectWrapper
                    key={`select-${this.state.assetId}`}
                    loadOptions={(inputValue, loadedOptions) =>
                        this.loadNext(loadedOptions)
                    }
                    onItemSelected={selected =>
                        this.handleChangeInPlan(selected)
                    }
                    title="Select Service Plan"
                    selectStyles={{
                        control: () => ({
                            display: 'flex',
                            'border-bottom': '1px solid #D3D7DA',
                        }),
                    }}
                    styles={{
                        container: addRecordStyles.servicePlanSelectContainer,
                        indicatorContainer: addRecordStyles.servicePlanIndicatorsContainer,
                    }}
                    showSelection
                    hideDivider
                    disableSearch
                    disableChips
                    selectedOptions={[selectedServicePlan]}
                />
            </FormControl>
        );
        return dropDown;
    };

    getDisplayValue = (originalValue) => {
        let displayValue = originalValue ? originalValue.toFixed(1) : '— —';
        if (displayValue.endsWith('.0')) displayValue = displayValue.substring(0, displayValue.length - 2);

        return displayValue;
    };

    deleteRecord() {
        const content = {
            id: this.state.recordId,
        };

        this.props.showLoader(true);

        deleteServiceRecord(content).then((response) => {
            this.props.showLoader(false);
            if (response.status === 204) {
                this.setState({
                    dialogType: 'successDelete',
                    openDialog: true,
                });
                analytics.track('DELETE_SERVICE_RECORD', { feature: 'Maintenance' });
            } else {
                this.setState({
                    dialogType: 'error',
                    openDialog: true,
                });
            }
        });
    }

    deleteHandler = () => {
        this.setState({
            dialogType: 'confirmDelete',
            openDialog: true,
            dialogTitle: '',
            isDelete: true,
        });
    };

    render() {
        const { classes } = this.props;
        const {
            distanceInterval, firstServiceOdometer, engineHoursInterval, firstServiceEngineHrs,
            maxOdometer, maxEngineHours,
        } = this.state;

        return (
            <div className={addRecordStyles.root}>
                <Paper>
                    <Toolbar className={customStyles.header}>
                        <span className={customStyles.backButton} >
                            <IconButton onClick={() => { this.handleCancelClick(); }} size="large">
                                <img src={back} alt="backArrow" />
                            </IconButton>
                        </span>
                        <Typography variant="h6" color="inherit">
                            <span>{this.props.windowTitle}</span>
                        </Typography>
                    </Toolbar>
                    <ValidatorForm
                        autoComplete="off"
                        onSubmit={() => { this.saveHandler(); }}
                    >
                        <Grid container className={customStyles.main} spacing={4}>
                            <Grid item xs={6} className={`${customStyles.firstCol} ${customStyles.gridItem}`}>
                                <Grid item className={customStyles.items}>
                                    <Typography className={customStyles.fieldTitle}>
                                        Asset
                                    </Typography>
                                    <div
                                        className={customStyles.formField}
                                        style={{ paddingTop: 6 }}
                                    >
                                        {
                                            this.props.editMode || this.props.prepopulated ?
                                                <TextField
                                                    id="asset-id-field"
                                                    className={customStyles.formField}
                                                    value={this.state.assetName}
                                                    margin="normal"
                                                    disabled
                                                /> :
                                                <AssetSearch
                                                    handleRowClick={this.handleRowClick}
                                                    handleClearField={this.handleAssetCleared}
                                                />
                                        }
                                    </div>
                                </Grid>
                                <Grid item className={customStyles.items}>
                                    <Typography className={customStyles.fieldTitle}>
                                        Service Plan
                                    </Typography>
                                    {
                                        this.props.editMode
                                        || this.props.prepopulated
                                        || !this.state.assetId ?
                                            <TextField
                                                id="service-plan-field"
                                                className={customStyles.formField}
                                                value={this.state.servicePlanName || 'Select Service Plan'}
                                                margin="normal"
                                                disabled
                                            /> :
                                            this.renderServicePlanDropDown()
                                    }
                                    {
                                        this.state.servicePlanDescription &&
                                        <div className={customStyles.plansDesc}>
                                            {this.state.servicePlanDescription}
                                        </div>
                                    }
                                </Grid>
                                <Grid item className={customStyles.items}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ display: 'none' }} />
                                                <TableCell className={`${customStyles.titleRow} ${customStyles.borderlessRow}`}>
                                                    <Typography className={customStyles.fieldTitle}>
                                                        Service Interval
                                                        <span>
                                                            <Tooltip title="When multiple criteria are selected, a reminder is issued only for the first criteria to reach its interval limit."><HelpIcon className={customStyles.helpIcon} /></Tooltip>
                                                        </span>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    Interval
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={9}>
                                                            First Service At
                                                            <br />
                                                            (Optional)
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Tooltip title="Optional: Enter a value at which you would like to have the alert notification sent for the first time."><HelpIcon className={customStyles.helpIcon} /></Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ display: 'none' }} />
                                                <TableCell className={`${customStyles.borderlessRow} ${customStyles.keyRow}`}>
                                                    Distance ({getDistanceString()})
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    { this.getDisplayValue(distanceInterval) }
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    { this.getDisplayValue(firstServiceOdometer) }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ display: 'none' }} />
                                                <TableCell className={`${customStyles.borderlessRow} ${customStyles.keyRow}`}>
                                                    Time Period (mo)
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    {this.state.timePeriodInterval ? this.state.timePeriodInterval : '— —'}
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    {this.state.firstServiceDate ? this.state.firstServiceDate : '— —'}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ display: 'none' }} />
                                                <TableCell className={`${customStyles.borderlessRow} ${customStyles.keyRow}`}>
                                                    Engine Hours (hrs)
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    { this.getDisplayValue(engineHoursInterval) }
                                                </TableCell>
                                                <TableCell className={customStyles.borderlessRow}>
                                                    { this.getDisplayValue(firstServiceEngineHrs) }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} className={customStyles.gridItem} >
                                <Grid item>
                                    <Typography className={customStyles.subtitle}>
                                        Odometer
                                    </Typography>
                                    <TextField
                                        id="odometer"
                                        placeholder="Enter Odometer"
                                        type="number"
                                        className={customStyles.textField}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: maxOdometer
                                                    ? Math.round(maxOdometer * 10) / 10 : null,
                                                step: 0.1,
                                            },
                                        }}
                                        value={this.state.odometer ? Math.round(this.state.odometer * 10) / 10 : ''}
                                        onChange={this.handleChange('odometer')}
                                        margin="normal"
                                    />
                                    <span>{getDistanceString() ? getDistanceString() : 'Mi'}</span>
                                </Grid>
                                <Grid item>
                                    <Typography className={customStyles.subtitle}>
                                        Service Date
                                    </Typography>
                                    <div className={customStyles.datePickerBox}>
                                        <LocalizationProvider dateAdapter={MomentUtils}>
                                            <DatePicker
                                                onChange={this.handleDateChange}
                                                renderInput={params =>
                                                    <TextField
                                                        {...params}
                                                        helperText={getDateErrorMessage(
                                                            this.state.dateErrorMessage,
                                                        )}
                                                        error={!!this.state.dateErrorMessage}
                                                    />
                                                }
                                                className={customStyles.datePicker}
                                                closeOnSelect
                                                PopperProps={{
                                                    placement: 'bottom-end',
                                                    sx: { '.MuiPickersToolbar-penIconButton': { display: 'none' } },
                                                }}
                                                showToolbar
                                                placeholder="mm/dd/yyyy"
                                                margin="dense"
                                                toolbarTitle=""
                                                value={this.state.serviceDate}
                                                format="MM/DD/YYYY"
                                                disableFuture
                                                mask="__/__/____"
                                                variant="inline"
                                                onError={(dateErrorMessage) => {
                                                    this.setState({ dateErrorMessage });
                                                }}
                                                id="date-picker-inline"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography className={customStyles.subtitle}>
                                        Engine Hours
                                    </Typography>
                                    <TextField
                                        id="engine-hours"
                                        placeholder="Enter Engine Hours"
                                        type="number"
                                        className={customStyles.textField}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: maxEngineHours
                                                    ? Math.round(maxEngineHours * 10) / 10 : null,
                                                step: 0.1,
                                            },
                                        }}
                                        value={this.state.engineHours ? Math.round(this.state.engineHours * 10) / 10 : ''}
                                        onChange={this.handleChange('engineHours')}
                                        margin="normal"
                                    />
                                    <span>hrs</span>
                                </Grid>
                                <Grid item>
                                    <Typography className={customStyles.subtitle}>Cost</Typography>
                                    <TextField
                                        id="cost"
                                        placeholder="Enter Cost"
                                        type="number"
                                        className={customStyles.textField}
                                        value={this.state.cost ? Math.round(this.state.cost * 100) / 100 : ''}
                                        onChange={this.handleChange('cost')}
                                        margin="normal"
                                    />
                                    <span>$</span>
                                </Grid>
                                <Grid item>
                                    <Typography className={customStyles.subtitle}>Notes</Typography>
                                    <TextField
                                        id="notes"
                                        placeholder="Enter Notes"
                                        className={customStyles.textField}
                                        value={this.state.notes}
                                        onChange={this.handleChange('notes')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid container direction="column">
                                    <Grid item xs={12}>
                                        <Typography className={customStyles.subtitle}>
                                            Please Select An Option<sup>*</sup>
                                            <span>
                                                <Tooltip title="This option is available if service date entered is most recent service date.">
                                                    <HelpIcon className={customStyles.helpIcon} />
                                                </Tooltip>
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ul
                                            className={customStyles.radioList}
                                            onChange={e => this.setResetStatus(e.target)}
                                        >
                                            <label htmlFor="ResetServiceInterval" className={customStyles.fullWidth}>
                                                <Radio
                                                    checked={this.state.resetStatus}
                                                    value
                                                    id="ResetServiceInterval"
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked,
                                                    }}
                                                />
                                                <span >Reset service interval</span>
                                            </label>
                                            <label htmlFor="DoNotResetServiceInterval" className={customStyles.fullWidth}>
                                                <Radio
                                                    checked={!this.state.resetStatus}
                                                    value={false}
                                                    id="DoNotResetServiceInterval"
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked,
                                                    }}
                                                />
                                                <span >DO NOT reset service interval</span>
                                            </label>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={customStyles.divider} />
                        <Toolbar sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <Grid item xs={6}>
                                <div className={customStyles.footNoteText}>
                                    {
                                        this.props.editMode && this.props.resetStatus &&
                                            this.getFootNoteText()
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Button
                                        className={classes.button}
                                        onClick={() => { this.handleCancelClick(); }}
                                    >
                                        Cancel
                                    </Button>
                                    { getPermissionValue('Maintenance') === 'Modify' &&
                                    this.props.editMode &&
                                    <Button
                                        className={classes.button}
                                        onClick={() => { this.deleteHandler(); }}
                                        disabled={getPermissionValue('Maintenance') !== 'Modify'}
                                    >
                                        Delete
                                    </Button>
                                    }
                                    { getPermissionValue('Maintenance') === 'Modify' &&
                                        <Button
                                            color="primary"
                                            className={classes.saveButton}
                                            variant="contained"
                                            type="submit"
                                            disabled={
                                                !this.state.assetId ||
                                                !this.state.servicePlanId ||
                                                !this.state.serviceDate ||
                                                getPermissionValue('Maintenance') !== 'Modify'
                                            }
                                        >
                                            Save
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </ValidatorForm>
                </Paper>
                <Dialog
                    open={this.state.openDialog}
                    type={this.getDialogType()}
                    customTitle={this.state.dialogTitle}
                    button={this.button}
                    content={this.getDialogContent()}
                    maxWidth="sm"
                />
            </div>
        );
    }
}
export default withStyles(addRecordStyles)(reduxConnect(AddServiceRecord, actions));
