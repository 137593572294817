/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_LOGGED_IN_USER, GRAILS_SSO_SIGNIN, ACCOUNT_SUSPENDED_LOGIN_ERROR_MSG, SSAS_URL_PARAMS, AUTH_SERVICE_LOGIN_HANDLER, UNABLE_TO_LOGIN } from './contants.login';
import {
    fetchLoggedInUserError,
    fetchLoggedInUserSuccess,
    loginError,
    loginSuccess,
    handleUnauthorized,
    ssoLoginError,
    loginSSAError,
} from './actions.login';
import config from '../../constants/Config';
import {
    updateAccountSettings,
    updateAppUserSettings,
    getUserToken,
    updateResellerInfo,
    getAppToken,
    setTermsAndConditions,
    isIframe,
    handleSpudNotification,
    setLocalStorageItem,
    setUserToken,
    removeLocalStorageItem,
    updateCustomerContact,
    checkDriverPagePermission,
    getGrailsAppBasedURL,
    getBearerToken,
    blockLoginDueToInstallAppPermission,
    clearLocalStorageAndCookieOnLogout,
} from '../../helper-classes/utility-functions';
import { SCORECARD_PREF_KEY } from '../ScoreCardDriverList/constants.scoredriverList';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
    postJSON: Function,
    get: Function,
}

// used to update local storage for login
const updateLocalStorage = (data: any) => {
    let matchedGlobalAccount = (data.validAccounts && data.validAccounts.length) > 0 ? data.validAccounts[0].id : '';
    if (data.impersonated) {
        setLocalStorageItem('fleet-impersonated', 'true');
        if (data.accountSettings && data.accountSettings.accountId) {
            matchedGlobalAccount = data.accountSettings.accountId;
        }
    }
    const accountUserId = data.userSettings ? data.userSettings.accountUserId : '';
    const accountName = data.userSettings ? data.userSettings.accountName : '';
    updateAppUserSettings(data.userSettings);
    setLocalStorageItem('currentAccountGlobalId', matchedGlobalAccount);
    setLocalStorageItem('currentAccountUserId', accountUserId);
    setLocalStorageItem('currentAccountName', accountName);
    setUserToken(data.token);
    setLocalStorageItem('selectedAccount', matchedGlobalAccount);
    updateAccountSettings(data.accountSettings);
    try {
        if (window.FLEET_CONFIG.DD_RUM_ENABLED) {
            window.DD_RUM.setUser({
                name: data.userSettings.username,
            });
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const fetchSpudNotification = (data: any) => {
    const newData = data;
    const headers = {
        Authorization: getBearerToken(data.token),
    };
    return newData.getJSON(`${getGrailsAppBasedURL()}/home/getStartupData`, headers)
        .mergeMap((result) => {
            newData.deviceFeature = result;
            newData.accountFeatures = result.accountFeatures;
            updateLocalStorage(newData);
            updateResellerInfo(result);
            handleSpudNotification('set', result);
            return [loginSuccess(),
                fetchLoggedInUserSuccess({ username: data.username }, data.userSettings, true)];
        })
        .catch(error => newData.of(loginError(error, data.username, 'Error in fetch deviceFeature call', false)));
};

export const getDriverPrefPath = (data: any) => {
    const headers = {
        'X-Nspire-UserToken': data.token,
    };
    const url = `${config.get('PLATFORM_API_BASE_URL')}/preferences/fleetLocate/${SCORECARD_PREF_KEY}`;

    return data.getJSON(url, headers)
        .mergeMap((result) => {
            const driverPreference = result && result.value ? result.value : '';
            setLocalStorageItem('driverPreference', driverPreference);
            return fetchSpudNotification(data);
        })
        .catch(() => fetchSpudNotification(data));
};

export const getAccountSettings = (data: any) => {
    const newData = data;
    const headers = {
        'X-Nspire-UserToken': data.token,
    };
    return newData.getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/accountSettings`, headers)
        .mergeMap((accountSettings) => {
            newData.accountSettings = accountSettings;
            if (checkDriverPagePermission(newData)) {
                return getDriverPrefPath(newData);
            }
            return fetchSpudNotification(newData);
        })
        .catch(error => newData.of(loginError(error, data.username, 'Error in get account settings call', false)));
};

export const fetchUserSettings = async (data: any) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: getBearerToken(data.token),
        },
    };
    const url = `${getGrailsAppBasedURL()}/extJs/userSettings?_cb=${new Date().getTime()}`;
    return fetch(url, options).then(response =>
        response.text().then((res) => res.substring(19, res.length - 1)))
        .then((res) => JSON.parse(res));
};

export const getUserSettings = (data: any) => {
    const newData = data;
    return data.Observable.merge(fetchUserSettings(data))
        .mergeMap((userSettings) => {
            const blockLogin = blockLoginDueToInstallAppPermission(userSettings);

            if (blockLogin) {
                const errorMessage = { response: { error: UNABLE_TO_LOGIN } };
                throw errorMessage;
            }
            newData.userSettings = userSettings;

            if (isIframe()) {
                return getAccountSettings(newData);
            }
            const resellerHeader = {
                'Content-Type': 'application/json',
                'X-Nspire-UserToken': data.token,
                'X-Nspire-AppToken': getAppToken(),
            };

            return newData.getJSON(`${config.get('PLATFORM_API_BASE_URL')}${SSAS_URL_PARAMS}`, resellerHeader)
                .mergeMap(resellerResponse => setTermsAndConditions(resellerResponse))
                .mergeMap(() => getAccountSettings(newData))
                .catch(error => newData.of(loginSSAError(error, data.username, 'Error in TermsAndConditions call')));
        })
        .catch(error => newData.of(loginError(error, data.username, 'Error in userSettings call', false)));
};

export const getAccountScopeToken = (data: any) => {
    let newData = data;
    if (data.accounts.length > 0) {
        newData.validAccounts = data.accounts;
        const headers = {
            'X-Nspire-UserToken': data.token,
            'X-Nspire-AppToken': data.appToken,
            'X-NSpire-Account': newData.validAccounts[0].id,
        };
        return data.getJSON(`${config.get('IDENTITY_BASE_URL')}/token?date=${new Date().getTime()}`, headers)
            .mergeMap((d) => {
                newData = { ...newData, ...d };
                return getUserSettings(newData);
            })
            .catch(error => data.of(loginError(error, data.username, 'Error in Account Scope Token Call', false)));
    }
    return data.of(loginError(ACCOUNT_SUSPENDED_LOGIN_ERROR_MSG, data.username, 'Accounts Login Enabled flag is false', true));
};

export const getAccountDetails = (data: any) => {
    const newData = { ...data, accounts: [] };
    const headers = {
        'X-Nspire-UserToken': data.token,
    };
    const timeStamp = (new Date()).getTime();
    return data.getJSON(`${config.get('PLATFORM_API_BASE_URL')}/accounts?date=${timeStamp}`, headers)
        .mergeMap((result) => {
            newData.accounts = result;
            removeLocalStorageItem('customerContact');
            updateCustomerContact(newData.accounts);
            if (newData.accounts.every(a => a.loginEnabled === false)) {
                return data.of(loginError(ACCOUNT_SUSPENDED_LOGIN_ERROR_MSG, data.username, 'Accounts Login Enabled flag is false', true));
            }
            return getAccountScopeToken(newData);
        })
        .catch(error => data.of(loginError(error, data.username, 'Error in Account Detail call', false)));
};

export const getLoggedInUser = (
    actions$: Function,
    store: Object,
    { getJSON, of }: ObservarblesTypes,
) =>
    actions$
        .ofType(FETCH_LOGGED_IN_USER)
        .mergeMap(() => {
            const userToken = getUserToken();
            const appToken = getAppToken();
            const headers = {
                'X-Nspire-AppToken': appToken,
                'X-Nspire-UserToken': userToken,
            };

            return getJSON(`${config.get('IDENTITY_BASE_URL')}/userDetail`, headers)
                .mergeMap((user) => {
                    const cloneUser = { ...user, userToken };
                    return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/accountSettings`, {
                        'X-Nspire-UserToken': userToken,
                    })
                        .mergeMap((accountSettings) => {
                            updateAccountSettings(accountSettings);
                            return fetchUserSettings({ token: userToken }).then((userSettings) => {
                                if (isIframe()) {
                                    return fetchLoggedInUserSuccess(
                                        cloneUser,
                                        userSettings,
                                        false,
                                    );
                                }
                                const ssaUrl = `${config.get('PLATFORM_API_BASE_URL')}${SSAS_URL_PARAMS}`;
                                return fetch(ssaUrl, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'X-Nspire-UserToken': getUserToken(),
                                        'X-Nspire-AppToken': getAppToken(),
                                    },
                                }).then(response => response.json())
                                    .then((resellerResponse) => {
                                        setTermsAndConditions(resellerResponse);
                                        return fetchLoggedInUserSuccess(
                                            cloneUser,
                                            userSettings,
                                            false,
                                        );
                                    });
                            });
                        })
                        .catch((error) => {
                            if (error.status === 401) {
                                return of(handleUnauthorized(error));
                            }
                            return of(fetchLoggedInUserError(error));
                        });
                })
                .catch((error) => {
                    if (error.status === 401) {
                        return of(handleUnauthorized(error));
                    }
                    return of(fetchLoggedInUserError(error));
                });
        });

export const grailAPISSOLogin = (actions$: Function, store: Object, {
    getJSON,
    of,
    postJSON,
    Observable,
}: ObservarblesTypes) =>
    actions$
        .ofType(GRAILS_SSO_SIGNIN)
        .distinctUntilChanged()
        .mergeMap((action) => {
            const appToken = getAppToken();
            const headers = {
                'X-Nspire-AppToken': appToken,
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-FleetPWA-BuildTag': window.FLEET_APP_BUILD_TAG && window.FLEET_APP_BUILD_TAG.sha,
            };

            const data = {
                headers,
                getJSON,
                of,
                appToken,
                username: action.payload.authUserName,
                postJSON,
                impersonated: true,
                token: action.payload.apiToken,
                Observable,
            };

            return getUserSettings(data)
        }).catch(error => of(ssoLoginError(error, '', 'Error in SSO Login')));

export const authServiceLoginHandler = (actions$: Function, store: Object, {
    getJSON,
    of,
    postJSON,
    getWithCredentials,
    Observable,
}: ObservarblesTypes) =>
    actions$
        .ofType(AUTH_SERVICE_LOGIN_HANDLER)
        .distinctUntilChanged()
        .mergeMap((action) => {
            const appToken = getAppToken();
            const headers = {
                'X-Nspire-AppToken': appToken,
                'X-FleetPWA-BuildTag': window.FLEET_APP_BUILD_TAG && window.FLEET_APP_BUILD_TAG.sha,
            };

            clearLocalStorageAndCookieOnLogout();
            const data = {
                headers,
                getJSON,
                getWithCredentials,
                of,
                Observable,
                appToken,
                postJSON,
                token: action.payload.apiToken,
            };
            return getAccountDetails(data);
        });

export default combineEpics(getLoggedInUser, grailAPISSOLogin, authServiceLoginHandler);
